<template>
  <div class="container mt-6">

    <h1 class="title">{{ $t('Сomplaints & Suggestions') }}</h1>
    <div>
      <div v-if="messageSent">
        <h3 class="is-4 title" >{{$t('Thank you!')}}</h3>
        <p class="subtitle mt-3">{{$t('Your message has been sent')}}</p>
        <p><span class="button is-primary" @click="resetForm">{{$t('Send another one')}}</span></p>
      </div>
      <form v-else action="#" @submit.prevent="handleSubmit">
        <b-field label="E-mail" :type="form.errors.has('email') ? 'is-danger' : ''"
                 :message="form.errors.get('email')" class="email-field">
          <b-input v-model="form.email"
                   @input="form.errors.clear('email')"
                   maxlength="30">
          </b-input>
        </b-field>
        <b-field  name="message" :label="$t('Message')" :type="form.errors.has('message') ? 'is-danger' : ''"
                 :message="form.errors.get('message')">
          <b-input @input="form.errors.clear('message')" maxlength="500" type="textarea" v-model="form.message"></b-input>
        </b-field>
        <b-field grouped>
          <b-field label="Captcha" >
            <b-field :type="form.errors.has('captcha_solution') ? 'is-danger' : ''" :message="form.errors.get('captcha_solution')">
              <div class="captcha-container" @click="loadCaptcha">
                <img :src="captchaImage" alt="captcha" class="captcha-img"/>
                <div class="label">{{$t('change')}}</div>
              </div>
              <b-input @input="form.errors.clear('captcha_solution')"
                       :placeholder="$t('enter the result of the expression')"
                       expanded
                       v-model="form.captcha_solution"></b-input>
            </b-field>
          </b-field>
        </b-field>
        <b-field :message="throttleMessage" :type="throttleMessage ? 'is-danger' : ''">
          <b-button @click="handleSubmit" type="is-primary" class="mt-3" :loading="form.busy">{{$t('Send')}}</b-button>
        </b-field>
      </form>
    </div>
  </div>
</template>

<script>
import Form from "vform";
export default {
  name: "Reports",
  data: () => ({
    form: new Form({
      email: null,
      message: null,
      captcha_solution: null,
      captcha_key: null,
    }),
    captchaImage: null,
    messageSent: false,
    throttleMessage: '',
  }),
  methods: {
    handleSubmit(){
      this.throttleMessage = '';
      this.form.post('reports')
        .then(() => {
          this.messageSent = true;
        }).catch((error) => {
          if (error.response.status === 429){
            this.throttleMessage = this.$t('Please, wait a minute');
          }
          this.loadCaptcha();
      });
    },
    resetForm(){
      this.form.reset();
      this.messageSent = false;
      this.loadCaptcha();
    },
    loadCaptcha(){
      this.$store.dispatch('base/getCaptcha').then(res => {
        this.captchaImage = res.data.img;
        this.form.captcha_key = res.data.key;
      });
    }
  },
  created() {
    this.loadCaptcha();
  }
};
</script>

<style scoped lang="scss">
@import "~bulma/sass/utilities/_all";

.email-field {
  @include desktop {
      max-width: 50%;
  }
}
.captcha-container{
  display: flex;
  justify-content: center;
  position: relative;
  cursor: pointer;
  .label {
    display: none;
    margin-top: 7.5px;
  }
  &:hover{
    .captcha-img {
      filter: contrast(20%);
    }
    .label {
      display: block;
      position: absolute;
      color: white;
    }
  }
}


</style>